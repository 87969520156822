// Here you can add other styles

.btn-primary,
.table-button {
  background-color: #00bbff !important;
  border-color: #00aaff !important;
  color: white !important;
}

.btn-primary:hover,
.table-button:hover {
  background-color: #00aaff !important;
  border-color: #0099ff !important;
  color: white !important;
}

.bg-primary {
  background-color: #777 !important;
}

.error-msg {
  color: red;
  border-radius: 5px;
  border: red 1px solid;
  background-color: rgba(255, 228, 228, 1);
  padding: 5px 12px;
  font-size: 15px;
}

.success-msg {
  color: rgb(27, 73, 0);
  border-radius: 5px;
  border: rgb(207, 255, 162) 1px solid;
  background-color: rgb(223, 255, 236);
  padding: 7px 12px;
  font-size: 15px;
}

.registration-completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
}

.registration-completed h1 {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 5px;
}

.registration-completed p {
  font-size: 18px;
  text-align: center;
  margin: 10px 0;
  padding: 15px;
}

.dropdown-item:hover,
.nav-link {
  cursor: pointer;
  user-select: none;
}

.ant-table-container th {
  background-color: #e7e7e7 !important;
}

.distr-policy {
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  margin-left: 10px;
  color: #000988;
  font-weight: 450;
}

.yt-icon {
  color: red !important;
}

#reserve-table tr:last-child td {
  font-weight: bold;
  border-top: 1px solid #555 !important;
  background-color: #eee;
}

#reserve-table th {
  background-color: #555 !important;
  color: white;
}

.if-link,
.if-link:visited {
  cursor: pointer;
  text-decoration-color: none;
  text-decoration: none;
  color: #109af7 !important;
  font-weight: 450;
}

.ant-checkbox-inner {
  border-color: #6a6a6a !important;
}

.news-node img {
  max-width: 100%;
  height: auto;
}

.top-popup {
  z-index: 999999;
}
